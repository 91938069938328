:root {
    --main: #0d5a2e;
    --heading: #0d5a2e;
    --white: #ffffff;
    --font: #0000009a;

}

* {
    font-family: 'Nunito', 'sans-serif';
}



.product-main {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin: 20px 15px;
    
    padding: 15px;

}

.product-head {
    padding-top: 8px !important;
    display: flex;
    /* font-family: 'Nunito' !important; */
    justify-content: space-between;
    align-items: center;
    padding: 0px 13px;
    flex-wrap: wrap;

}


.product-head h2 {
    margin: 5px 0px;
    color: var(--heading);
    /* font-family: 'Nunito' !important; */

}

.product-button-group {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;

}

.product-button {
    margin: 0px 10px !important;
    /* font-weight: 600 !important; */
    padding: 6px 16px !important;
    font-size: 0.875rem !important;
}

Table css .product-table {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
    border-radius: 10px;

}

.table-loader {
    text-align: center;
    padding: 50px;
}

table {
    /* font-family: 'Nunito' !important; */
    text-indent: initial;
    border-spacing: 0px;
    width: 100%;
}

#product-table {
    white-space: nowrap;
    margin-bottom: 0%;
    border-bottom: 2px solid #e8eaf6;
    margin-top: 13px;
    width: 100%;
}


#product-table tr th {
    font-size: 1rem;
    cursor: pointer;
    text-align: left;
    padding: 0px 10px;
    padding-bottom: 8px;
}


.product-searchbar th input {
    padding: 5px 0px;
    margin: 8px 0px;
    border-radius: 2px;
    border: #e8eaf6;
    width: 100%;

}

.input-dis {
    cursor: not-allowed;
    background-color: var(--white);
}

#select {
    height: 35px;
    padding: 3px 4px;
    border-radius: 2px;
    border: #e8eaf6;
    width: 100%;
}

.product-searchbar {
    background-color: #e8eaf6;
}

.product-searchbar th {
    border-left: 4px solid #e8eaf6;
    border-right: 4px solid #e8eaf6;
    padding: 0px !important;
}

.table-data:nth-child(even) {
    background-color: rgb(249, 249, 249, 1);
}

.table-data td {
    padding: 0px 10px !important;
    height: 30px !important;
}

#product-table tr td {
    font-size: 16px;
    overflow: hidden;
    color: var(--font) !important;
    padding: 5px !important;
}

.actionButton {
    padding: 5px !important;
}

/* .MuiIconButton-root {
    padding: 5px;
} */

#table-data-div {
    padding: 0px 10px;
    display: flex;
    align-items: center;
}

.active-lable {
    font-size: 12px;
    /* font-weight: bold; */
    margin: 0px;
    align-items: center;
}

scroll style::-webkit-scrollbar {
    width: 0.4em;
    height: 0.6em;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
    -webkit-box-she: inset 0 0 6px rgba(0, 0, 0, 0.00);
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--heading);
    outline: 1px solid rgb(107, 149, 190);
}


.calculator-main-tabView {
    display: flex;
    justify-content: space-evenly;

    font-size: 12px;
    text-align: center;
    border: 1px solid var(--main);
    border-radius: 5px;
}

.calculator-tabView-active {

    background: var(--main);
    padding: 3px 19px;
    border-radius: 3px;
    color: var(--white);
    /* font-weight: 600; */
}

.calculator-tabView-deActive {
    margin: 0px 10px;
    background: var(--hover-font-color);
    padding: 3px 0px;

    border-radius: 5px;
    cursor: pointer;
    /* font-weight: 600; */
}

.calculator-tabView-active2 {

    background: var(--main);
    padding: 3px 19px;
    border-radius: 3px;
    color: var(--white);
    /* font-weight: 600; */
}

.calculator-tabView-deActive2 {
    margin: 0px 10px;
    background: var(--hover-font-color);
    padding: 3px 0px;

    border-radius: 5px;
    cursor: pointer;
    /* font-weight: 600; */
}

.title-content {
    /* font: normal normal normal 16px/19px 'Nunito'; */
    letter-spacing: 0.4px;
    color: #646464;
}