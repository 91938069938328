.MuiDialogTitle-root:after {
    left: 26px;
    width: 77% !important;
    bottom: 0;
    height: 4px;
    content: "";
    position: absolute;
    color: black;
    /* background: #0d5a2e; */
}
*{
    font-family: 'Nunito', 'sans-serif' !important;
}