.navbar {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adding box shadow */
   height: 49px; 
  
  }
  
  .navbar-menu {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    margin: 0px 20px;
    padding: 0;
    justify-content: end;
  
  }
  
  .navbar-menu li {
    margin-right: 20px;
  }
  
  .navbar-menu li:last-child {
    margin-right: 0;
  }
  
  .navbar-menu li a {
    color: black;
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
}
  
  .navbar-menu li.active a {
    background-color: #4caf50;
    color: white;
  }
